<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.trainee_evaluation_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
            <b-row>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                  <b-row>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                            </template>
                            <v-select name="organization"
                              v-model="search.fiscal_year_id"
                              label="text"
                              :reduce="item => item.value"
                              :options= fiscalYearList
                              :placeholder="$t('globalTrans.select')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            />
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                      <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.org_id"
                            :options="orgList"
                            :disabled="!$store.state.Auth.activeRoleId || $store.state.Auth.activeRoleId !== 1"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="training_type_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_type_id"
                            :options="trainingTypeList"
                            id="training_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Training Category"  vid="training_category_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="training_category_id"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="search.training_category_id"
                              :options="trainingCategoryList"
                              id="training_category_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                      </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Training Title"  vid="training_title_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="training_title_id"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="search.training_title_id"
                              :options="trainingTitleList"
                              id="training_title_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                        </b-form-group>
                      </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="batch_no"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.batch_no')}}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.batch_no"
                              :options="batchList"
                              id="batch_no"
                              >
                              <template v-slot:first>
                                  <b-form-select-option value=0>{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="circular_memo_no"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.circular_memo_no')}}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.circular_memo_no"
                              :options="circularList"
                              id="circular_memo_no"
                              >
                              <template v-slot:first>
                                  <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                        </b-form-group>
                      </b-col>
                  </b-row>
                  <div class="row">
                    <b-col sm="9">
                    </b-col>
                    <b-col sm="3" style="text-align: right;">
                      <b-button size="sm" type="submit" variant="primary" class="mt-20">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                      </b-button>
                    </b-col>
                  </div>
                </b-form>
              </ValidationObserver>
              </b-row>
          </template>
        </iq-card>
        <b-row>
          <b-col md="12">
            <b-overlay>
              <b-row v-if='search.details.length > 0'>
                <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('tpm_report.trainee_evaluation_report') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" @click="pdfExport">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay>
                        <b-row mt-5>
                          <b-col md="12" class="table-responsive">
                            <div style="border: 2px solid;margin:10px;padding:10px">
                              <b-row>
                                <b-col>
                                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                    <template v-slot:projectNameSlot>
                                      {{ }}
                                    </template>
                                    {{ $t('tpm_report.training_course_evaluation_report') }}
                                  </list-report-head>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <div class="text-center">
                                    <table style="width:100%;color:black;">
                                      <tr>
                                        <td align="left" style="width:10%">{{ $t('elearning_config.fiscal_year') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:25%" v-if="search.fiscal_year_id"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.fiscal_year_bn : searchHeaderData.fiscal_year_en }}</b></td>
                                        <td align="left" style="width:50%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                        <td align="left" style="width:12%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:25%" v-if="search.circular_memo_no"><b>{{ ($i18n.locale==='bn') ? search.circular_memo_no : search.circular_memo_no }}</b></td>
                                        <td align="left" style="width:25%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                      </tr>
                                      <tr>
                                        <td align="left" style="width:10%">{{ $t('elearning_config.training_title') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:25%" v-if="search.training_title_id"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.training_title_bn : searchHeaderData.training_title_en }}</b></td>
                                        <td align="left" style="width:25%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                        <td align="left" style="width:12%">{{ $t('elearning_iabm.batch_no') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:25%" v-if="search.batch_no"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.batch_bn : searchHeaderData.batch_en }}</b></td>
                                        <td align="left" style="width:25%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                      </tr>
                                    </table>
                                  </div>
                                </b-col>
                              </b-row>
                              <!-- <b-row>
                                <b-col>
                                    <div class="text-center">
                                        <table style="width:100%;color:black;">
                                            <tr v-if="search.circular_memo_no">
                                                <td align="right" style="width:45%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.circular_memo_no }}</td>
                                            </tr>
                                            <tr v-if="search.training_type_id">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_type') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                                            </tr>
                                            <tr v-if="search.training_category_id">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                            </tr>
                                            <tr v-if="search.training_title_id">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </b-col>
                              </b-row> -->
                              <b-row>
                                <div class="col-md-12" id="form">
                                  <div class="col-12">
                                    <b-table-simple hover small caption-top responsive border v-if="search.details.length > 0">
                                      <b-thead class="thead">
                                        <b-tr>
                                          <b-th style="vertical-align: middle;width:80px" class="text-center">{{$t('globalTrans.sl_no')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('globalTrans.name')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('globalTrans.mobile')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('elearning_tim.posting_office')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-right">{{$t('globalTrans.mark')}}</b-th>
                                          <b-th class="text-right">
                                            {{$t('tpm_report.pre_evaluation')}}
                                          </b-th>
                                          <b-th style="vertical-align: middle" class="text-right">{{$t('globalTrans.mark')}}</b-th>
                                          <b-th class="text-right">
                                            {{$t('tpm_report.post_evaluation')}}
                                          </b-th>
                                        </b-tr>
                                      </b-thead>
                                      <b-tr v-for="(course, index) in search.details" :key="index">
                                        <b-td class="text-center">{{$n(index+1)}}</b-td>
                                        <b-td class="text-center">
                                          {{ ($i18n.locale==='bn') ? course.name_bn : course.name }}
                                        </b-td>
                                        <b-td class="text-center">
                                          {{ course.mobile }}
                                        </b-td>
                                        <b-td class="text-left">
                                          <slot v-if="course.training_status === 1">
                                            {{ ($i18n.locale==='bn') ? course.designation_name_bn : course.designation_name }}
                                          </slot>
                                          <slot v-else>
                                            {{ course.designation }}
                                          </slot>
                                          {{ (($i18n.locale==='bn') ? course.office_name_bn : course.office_name) + ', ' + (($i18n.locale==='bn') ? course.org_name_bn : course.org_name) }}
                                        </b-td>
                                        <b-td class="text-right">
                                          {{ course.total_mark_pre }}
                                        </b-td>
                                        <b-td class="text-right">
                                          {{ course.obtained_mark }}
                                        </b-td>
                                        <b-td class="text-right">
                                          {{ course.total_mark_post }}
                                        </b-td>
                                        <b-td class="text-right">
                                          {{ course.obtained_mark_post }}
                                        </b-td>
                                      </b-tr>
                                    </b-table-simple>
                                    <template v-if="search.details.length === 0">
                                        <br/>
                                        <br/>
                                        <h5 class="text-center text-danger" style="height: 80px">{{$t('globalTrans.noDataFound')}}</h5>
                                    </template>
                                  </div>
                                </div>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
          <!-- <pre>{{search}}</pre> -->
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { manualCourseEvaluation, circularList } from '../../api/routes'
// import { trainingCourseSummaryReport, manualCourseEvaluation, circularPublication } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    created () {
      this.search = Object.assign({}, this.search, {
        // org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
      }
    },
    data () {
        return {
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          search: {
            circular_memo_no: '',
            batch_no: 0,
            training_type_id: 0,
            org_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            course_id: 0,
            details: []
          },
          searchHeaderData: {
            fiscal_year_en: '',
            fiscal_year_bn: '',
            org_en: '',
            org_bn: '',
            circular_memo_no: '',
            training_type_en: '',
            training_type_bn: '',
            training_title_en: '',
            training_title_bn: '',
            batch_en: '',
            batch_bn: ''
          },
          trainingTypeList: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          courseList: [],
          circularList: [],
          allBatchDataList: [],
          batchList: [],
          circularLoading: false,
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          allreadyApplicant: [],
          courses: [],
          loading: false,
          showData: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
        this.getCircularList()
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      currentFiscalYearId: function () {
        return this.$store.state.TrainingElearning.currentFiscalYearId
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
        // trainingTypeList: function () {
        //     return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        // },
        i18 () {
            return this.$i18n.locale
        }
    },
    watch: {
        // 'search.circular_memo_no': function (newValue) {
        //     this.courseList = this.getCourseList(newValue)
        // },
        // 'search.training_type_id': function (newValue) {
        //     this.trainingCategoryList = this.getCategoryList(newValue)
        // },
        // 'search.training_category_id': function (newValue) {
        //     this.trainingTitleList = this.getTrainingTitleList(newValue)
        // }
        'search.org_id': function (newValue) {
          this.trainingTypeList = this.getTypeList(newValue)
        },
        'search.training_type_id': function (newValue) {
          this.trainingCategoryList = this.getCategoryList(newValue)
        },
        'search.training_category_id': function (newValue) {
          this.trainingTitleList = this.getTrainingTitleList(newValue)
        },
        'search.training_title_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.getCircularList()
          }
        }
    },
    methods: {
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      getTypeList (orgId) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.search.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id,
          training_type_id: this.search.training_type_id,
          training_category_id: this.search.training_category_id,
          training_title_id: this.search.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      },
      //   async getCircularList () {
      //     this.circularLoading = true
      //     const serchData = {
      //       org_id: this.$store.state.dataFilters.orgId
      //     }
      //     const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_training_calendar', serchData)
      //     if (!result.success) {
      //     } else {
      //       const listObject = result.data
      //       const tmpList = listObject.map((obj, index) => {
      //         if (this.$i18n.locale === 'bn') {
      //           return { value: obj.circular_memo_no, text: obj.circular_memo_no }
      //         } else {
      //           return { value: obj.circular_memo_no, text: obj.circular_memo_no }
      //         }
      //       })
      //       this.circularList = tmpList
      //     }
      //     this.circularLoading = false
      // },
      pdfExport () {
          const reportTitle = this.$t('tpm_report.trainee_evaluation_report ')
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.search, this.courses)
      },
      getCourseList (circularMemoNo) {
        const courseList = this.$store.state.TrainingElearning.commonObj.courseList
        if (circularMemoNo) {
          return courseList.filter(item => item.circular_memo_no === String(circularMemoNo))
        }
        return courseList
      },
      // async circularPublicationAdd () {
      //   if (this.search.circular_memo_no) {
      //       this.load = true
      //       const params = {
      //         circular_memo_no: this.search.circular_memo_no,
      //         table: 'tpm_training_calendar'
      //       }
      //       const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      //       if (!result.success) {
      //       this.load = false
      //       } else {
      //       this.courses = result.courses
      //       if (this.search.course_id) {
      //           this.courses = result.courses.filter(item => item.id === parseInt(this.search.course_id))
      //       }
      //       this.searchData(this.search.circular_memo_no)
      //       }
      //   } else {
      //     this.allreadyApplicant = []
      //     this.search.details = []
      //   }
      // },
      async searchData () {
        this.loading = true
        this.showData = true
        if (this.search.fiscal_year_id) {
          const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
          const customItem = {
            fiscal_year_en: fiscalYear?.text_en,
            fiscal_year_bn: fiscalYear?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
        }
        if (this.search.circular_memo_no) {
          const circularObj = this.circularList.find(obj => obj.value === this.search.circular_memo_no)
          const circularData = {
            circular_memo_no_en: circularObj?.text,
            circular_memo_no_bn: circularObj?.text
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, circularData)
        }
        if (this.search.training_title_id) {
          const titleObj = this.trainingTitleList.find(obj => obj.value === this.search.training_title_id)
          const titleData = {
            training_title_en: titleObj?.text_en,
            training_title_bn: titleObj?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, titleData)
        }
        if (this.search.batch_no) {
          const batchObj = this.batchList.find(obj => obj.value === this.search.batch_no)
          const batchData = {
            batch_en: batchObj?.text_en,
            batch_bn: batchObj?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, batchData)
        }
        this.getCustomDataSearch()
        const searchData = this.search
        searchData.details = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, manualCourseEvaluation, searchData)
        if (!result.success) {
          this.allreadyApplicant = []
          this.search.details = []
        } else {
          this.allreadyApplicant = result.allreadyApplicant
          if (this.allreadyApplicant.length > 0) {
            const datas = result.data.map(item => {
              const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
              const desigData = {}
              if (typeof desigObj !== 'undefined') {
                desigData.designation_name = desigObj.text_en
                desigData.designation_name_bn = desigObj.text_bn
              } else {
                desigData.designation_name = ''
                desigData.designation_name_bn = ''
              }
              const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_org_id))
              const orgData = {}
              if (typeof orgObj !== 'undefined') {
                orgData.org_name = orgObj.text_en
                orgData.org_name_bn = orgObj.text_bn
              } else {
                orgData.org_name = ''
                orgData.org_name_bn = ''
              }
              const officeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.professional_office_id))
              const officeData = {}
              if (typeof officeObj !== 'undefined') {
                officeData.office_name = officeObj.text_en
                officeData.office_name_bn = officeObj.text_bn
              } else {
                officeData.office_name = ''
                officeData.office_name_bn = ''
              }
              const allreadyCourseObj = this.allreadyApplicant.find(allready => allready.training_application_id === parseInt(item.id) && allready.type === 'pre')
              const allreadyCourseObjpost = this.allreadyApplicant.find(allready => allready.training_application_id === parseInt(item.id) && allready.type === 'post')
              const trData = {}
              trData.total_mark_pre = typeof allreadyCourseObj !== 'undefined' ? allreadyCourseObj.total_mark : 0
              trData.obtained_mark = typeof allreadyCourseObj !== 'undefined' ? allreadyCourseObj.obtained_mark : 0
              trData.total_mark_post = typeof allreadyCourseObjpost !== 'undefined' ? allreadyCourseObjpost.total_mark : 0
              trData.obtained_mark_post = typeof allreadyCourseObjpost !== 'undefined' ? allreadyCourseObjpost.obtained_mark : 0
              return Object.assign({}, item, desigData, orgData, officeData, trData)
            })
            this.search.details = datas
          }
        }
      },
      getCustomDataSearch () {
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.search.training_type_id))
        if (typeof trainingTypeObj !== 'undefined') {
            this.search.training_type = trainingTypeObj.text_en
            this.search.training_type_bn = trainingTypeObj.text_bn
        } else {
            this.search.training_type = ''
            this.search.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(this.search.training_category_id))
        if (typeof trainingCategoryObj !== 'undefined') {
            this.search.training_category = trainingCategoryObj.text_en
            this.search.training_category_bn = trainingCategoryObj.text_bn
        } else {
            this.search.training_category = ''
            this.search.training_category_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(this.search.training_title_id))
        if (typeof trainingTitleObj !== 'undefined') {
            this.search.training_title = trainingTitleObj.text_en
            this.search.training_title_bn = trainingTitleObj.text_bn
        } else {
            this.search.training_title = ''
            this.search.training_title_bn = ''
        }
      }
    }
}
</script>
