import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm, datas, courses) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
              columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
          ]
      // const allRowsHead = []
      // if (datas.circular_memo_no) {
      //   const rowItem = [
      //     { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'td', alignment: 'right' },
      //     { text: ':', style: 'td', alignment: 'center' },
      //     { text: datas.circular_memo_no, style: 'td', alignment: 'left' }
      //   ]
      //   allRowsHead.push(rowItem)
      // }
      const allRowsHead = []
        const rowItem = [
          { text: vm.$t('elearning_config.fiscal_year'), style: 'td', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? vm.searchHeaderData.fiscal_year_bn : vm.searchHeaderData.fiscal_year_en, style: 'td', alignment: 'left' },
          { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'td', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (datas.circular_memo_no) ? (i18n.locale === 'bn') ? vm.search.circular_memo_no : vm.search.circular_memo_no : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' }
        ]
        allRowsHead.push(rowItem)
        const rowItem1 = [
          { text: vm.$t('elearning_config.training_title'), style: 'td', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? vm.searchHeaderData.training_title_bn : vm.searchHeaderData.training_title_en, style: 'td', alignment: 'left' },
          { text: vm.$t('elearning_iabm.batch_no'), style: 'td', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (datas.batch_no) ? (i18n.locale === 'bn') ? vm.searchHeaderData.batch_bn : vm.searchHeaderData.batch_en : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' }
        ]
        allRowsHead.push(rowItem1)
      if (allRowsHead.length > 0) {
        pdfContent.push({
          table: {
            widths: ['18%', '3%', '30%', '18%', '3%', '30%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
      }
      pdfContent.push({ text: '', style: 'tableMargin' })
        // option
      const allRows = []
      const firstData = []
      firstData.push(
        { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('elearning_tim.posting_office'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('globalTrans.mark'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('tpm_report.pre_evaluation'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('globalTrans.mark'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('tpm_report.post_evaluation'), style: 'th', alignment: 'center', bold: true }
      )
      allRows.push(firstData)
      datas.details.filter((course, optionIndex) => {
        let dataDes = ''
        let officeData = ''
        if (course.training_status === 1) {
          dataDes = vm.$i18n.locale === 'bn' ? course.designation_name_bn : course.designation_name
        } else {
          dataDes = course.designation
        }
        officeData = vm.$i18n.locale === 'bn' ? course.office_name_bn : course.office_name + ', ' + vm.$i18n.locale === 'bn' ? course.org_name_bn : course.org_name
        const rowItem = [
          { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? course.name_bn : course.name, style: 'td', alignment: 'center' },
          { text: ((vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(course.mobile, { useGrouping: false })), style: 'td', alignment: 'center' },
          { text: dataDes + officeData, style: 'td', alignment: 'left' },
          { text: vm.$n(course.total_mark_pre, { useGrouping: false }), style: 'td', alignment: 'right' },
          { text: vm.$n(course.obtained_mark, { useGrouping: false }), style: 'td', alignment: 'right' },
          { text: vm.$n(course.total_mark_post, { useGrouping: false }), style: 'td', alignment: 'right' },
          { text: vm.$n(course.obtained_mark_post, { useGrouping: false }), style: 'td', alignment: 'right' }
        ]
        allRows.push(rowItem)
      })
      pdfContent.push({ text: '', style: 'tableMargin' })
      pdfContent.push({
        table: {
          headerRows: 2,
          widths: ['15%', '15%', '10%', '20%', '10%', '10%', '10%', '10%'],
          body: allRows
        }
      })
        pdfContent.push({ text: '', style: 'fertilizer' })
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
